<template>
  <div>
    <b-overlay :show="loading" rounded="lg" opacity="0.7">
      <div id="ComponentFormRelatorioAcompanhamento" class="p-2 card">
        <h2 class="mb-3">Exportar Dados</h2>
        <span class="align-text-top mb-2">
          Selecione abaixo o grupo, empresa e fase dos quais deseja exportar as informações. Será gerado um arquivo CSV.
          <b>
            Atenção! Estes dados são pessoais e sensíveis. Evite ao máximo compartilhá-los e utilize somente para fins
            relacionados com a aplicação e devolutiva do Planeja Saúde.
          </b>
        </span>
        <validation-observer ref="simpleRules">
          <b-form
            id="formRelatorioAcompanhamento"
            ref="formRelatorioAcompanhamentoRef"
            @submit.prevent="submitForm()"
          >
            <div id="RelatorioAcompanhamento">
              <b-row>
                <b-col md="4" class="mb-1">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-group
                      :state="errors.length > 0 ? false : null"
                      label="Grupo*"
                      label-for="Select-Grupo"
                    >
                      <v-select
                        id="Select-Grupo"
                        v-model="form.grupo.selecionado"
                        @input="setGrupo"
                        variant="custom"
                        item-text="descricao"
                        item-value="id_empresa_grupo"
                        label="descricao"
                        :options="form.grupo.dadosSelect"
                        placeholder="Selecione o Grupo"
                        :disabled="form.grupo.disabled"
                      >
                        <span slot="no-options">Nenhum Grupo encontrado.</span>
                      </v-select>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >
                        Campo Obrigatório
                      </small >
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4" class="mb-1">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-group
                      :state="errors.length > 0 ? false : null"
                      label="Empresa*"
                      label-for="Select-Filial"
                    >
                      <v-select
                        id="Select-Filial"
                        v-model="form.filiais.selecionado"
                        @input="setFiliais"
                        variant="custom"
                        item-text="nomeDescricao"
                        item-value="id_empresa"
                        label="nomeDescricao"
                        :options="form.filiais.dadosSelect"
                        placeholder="Selecione a Empresa"
                        :disabled="form.filiais.disabled"
                      >
                        <span slot="no-options">Nenhuma Empresa encontrada.</span>
                      </v-select>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >
                        Campo Obrigatório
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
                <b-col md="4" class="mb-1">
                  <validation-provider #default="{ errors }" rules="required">
                    <b-form-group
                      :state="errors.length > 0 ? false : null"
                      label="Fase*"
                      label-for="Select-Fase"
                    >
                      <v-select
                        id="Select-Fase"
                        v-model="form.fases.selecionado"
                        variant="custom"
                        item-text="descricao"
                        item-value="id_fase"
                        label="descricao"
                        :options="form.fases.dadosSelect"
                        placeholder="Selecione a Fase"
                        :disabled="form.fases.disabled"
                      >
                        <span slot="no-options">Nenhuma fase encontrada.</span>
                      </v-select>
                      <small
                        v-if="errors.length > 0"
                        class="text-danger"
                      >
                        Campo Obrigatório
                      </small>
                    </b-form-group>
                  </validation-provider>
                </b-col>
              </b-row>
            </div>
            <b-row class="botoes">
              <b-col md="12" align="right">
                <b-button
                  type="submit"
                  variant="custom"
                  class="mr-1"
                  :disabled="
                    !form.grupo.selecionado ||
                    !form.filiais.selecionado ||
                    !form.fases.selecionado ?
                    true : false
                  "
                  @click.prevent="submitForm(false)"
                >
                  <span>Exportar</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </validation-observer>
      </div>
      <div class="p-2 card">
        <div v-if="carregandoTabela" class="w-100 mt-5 d-flex flex-column align-items-center justify-content-center">
          <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
          <span>Buscando dados...</span>
        </div>
        <section>
          <b-row class="my-2">
            <b-col sm="12" class="my-2">
              <div v-if="!mostraAlertaTabelaVazio" >
                <b-table
                  id="listCompaniesTable"
                  @context-changed="ordenarTabela"
                  sticky-header="100%"
                  :busy.sync="tabela.tabelaOcupada"
                  :no-local-sorting="true"
                  :fields="tabela.fields"
                  :items="tabela.items"
                >
                  <template #head(nome_relatorio)="data">
                    <feather-icon icon="GridIcon" class="mr-1-variant mt-n1-variant"/>
                    {{ data.label }}
                  </template>
                  <template #head(data_relatorio)="data">
                    <feather-icon icon="CalendarIcon" class="mr-1-variant mt-n1-variant"/>
                    {{ data.label }}
                  </template>
                  <template #head(situacao_relatorio)="data">
                    <feather-icon icon="LoaderIcon" class="mr-1-variant mt-n1-variant"/>
                    {{ data.label }}
                  </template>
                  <template #head(acoes)="data">
                    <feather-icon icon="CheckCircleIcon" class="mr-1-variant mt-n1-variant"/>
                    {{ data.label }}
                  </template>
                  <template #cell(acoes)="row">
                    <div class="text-center column-acoes">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        v-b-tooltip.hover.v-secondary
                        v-b-tooltip.hover.bottom="'Baixar o arquivo'"
                        @click="downloadRelatorio(row.item)"
                        :variant="
                          row.item.situacao_relatorio === 'Concluido' ?
                          'outline-primary' :
                          'outline-secondary'"
                        :class="
                          row.item.situacao_relatorio === 'Concluido' ?
                          'btn-icon btn-acao-tabela' :
                          'btn-icon disabled btn-acao-tabela cursor-na'"
                        :disabled="
                          row.item.situacao_relatorio === 'Concluido'?
                          false:
                          true"
                      >
                        <feather-icon icon="DownloadIcon"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>
              <div v-else>
                <b-alert
                  variant="primary"
                  :show="mostraAlertaTabelaVazio"
                  class="mt-5"
                >
                  <div class="alert-body d-flex justify-content-center">
                    <span class="text-primary">
                      <strong class="text-primary">Nenhum registro encontrado.</strong>
                    </span>
                  </div>
                </b-alert>
              </div>
            </b-col>
          </b-row>
        </section>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  modalGenericModel
} from '@/libs/sweetalerts';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import vSelect from "vue-select";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from 'moment';
import Ripple from 'vue-ripple-directive';
import {
  BFormInput,
  BRow,
  BCol,
  BFormGroup,
  BForm,
  BButton,
  BCardText,
  BAlert,
  BOverlay,
  BModal,
  BBadge,
  BInputGroup,
  BInputGroupPrepend,
  BFormSelect,
  BTable,
  VBTooltip,
  BSpinner,
} from "bootstrap-vue";
export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    required,
    vSelect,
    VuePerfectScrollbar,
    moment,
    BFormInput,
    BRow,
    BCol,
    BFormGroup,
    BForm,
    BButton,
    BCardText,
    BAlert,
    BOverlay,
    BModal,
    BBadge,
    BInputGroup,
    BInputGroupPrepend,
    BFormSelect,
    BTable,
    VBTooltip,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      loading: true,
      mostraAlertaTabelaVazio: true,
      relatorios: [],
      carregandoTabela: true,
      form: {
        grupo: {
          selecionado: "",
          idGrupo: null,
          disabled: false,
          dadosSelect: [],
          disabledButton: true,
        },
        filiais: {
          selecionado: "",
          disabled: true,
          dadosSelect: [],
          disabledButton: true,
          idEmpresa: null,
        },
        fases: {
          selecionado: null,
          disabled: true,
          dadosSelect: [],
        },
      },
      tabela: {
        semDados: false,
        erroTabela: false,
        tabelaOcupada: false,
        ordem: '',
        campoOrdenado: '',
        fields: [
          {key: "nome_relatorio", label: "Relatório",},
          {key: "data_relatorio", label: "Data", class: 'text-center'},
          {key: "situacao_relatorio", label: "Situação", class: 'text-center'},
          {key: "acoes", label: "Ações", class: 'text-center text-center'}
        ],
        items: []
      },
    };
  },
  mounted() {
    this.populaGrupos();
    this.disabledFormCriacao(true);
    this.listaRelatorios();

    this.$eventHub.on('atualizarDadosRelatorio',(_data)=>{
      this.limparTabela();
      this.listaRelatorios();
    });
  },
  methods: {
    populaGrupos() {
      let rota = this.$helpers.preparaRotaComParametros(
        this.$api.GruposBuscaPorModulo,
        "{idModulo}",
        2
      );
      this.$http
        .get(rota)
        .then((res) => {
          this.form.grupo.dadosSelect = res.data.data;
          this.loading = false;
        });
    },
    disabledFormCriacao(disabled) {
      this.form.grupo.disabled   = !disabled;
      this.form.filiais.disabled = disabled;
      this.form.fases.disabled   = disabled;
    },
    async listaRelatorios(){
      this.carregandoTabela = true;
      this.mostraAlertaTabelaVazio = false;
      await this.limparTabela();
      let rota = this.$api.PlanejaSaude.listarUltimosRelatorios;
      await this.$http.get(rota).then((res) => {
        this.transformarParaFormato(res.data);
        this.tabela.items = this.relatorios;
        this.mostraAlertaTabelaVazio = this.tabela.items.length > 0 ? false : true;
      });
      this.carregandoTabela = false;
    },
    async limparTabela(){
      this.relatorios = [];
      this.tabela.items = [];
    },
    formatarRelatorio(objeto) {
      return {
        data_relatorio: this.formatarData(objeto.criacao),
        id_relatorio: objeto.id_relatorio,
        id_empresa: objeto.id_empresa,
        id_fase: objeto.id_fase,
        id_anexo: objeto.id_anexo,
        situacao_relatorio: this.formatarSituacao(objeto.situacao),
        nome_relatorio: objeto.nome_relatorio,
      };
    },
    formatarData(data) {
      const dataFormatada = new Date(data);
      return `
        ${this.pad(dataFormatada.getDate())}/${this.pad(dataFormatada.getMonth() + 1)}/${dataFormatada.getFullYear()}
        às ${this.pad(dataFormatada.getHours())}:${this.pad(dataFormatada.getMinutes())}:${this.pad(dataFormatada.getSeconds())}
      `;
    },
    pad(numero) {
      return numero < 10 ? '0' + numero : numero;
    },
    formatarSituacao(situacao) {
      return situacao.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    },
    transformarParaFormato(data) {
      data.forEach(relatorio => {
        this.relatorios.push(this.formatarRelatorio(relatorio))
      });
    },
    setGrupo() {
      if (this.form.grupo.selecionado) {
        this.populaEmpresasDoGrupo(
          this.form.grupo.selecionado.matriz,
          this.form.grupo.selecionado.filiais
        );
      } else {
        this.limpaForm();
      }
    },
    populaEmpresasDoGrupo(matriz, filiais) {
      this.form.filiais.disabled = true;
      matriz.nomeDescricao =
        matriz.nome_fantasia + " - " + this.$helpers.formataCnpj(matriz.cnpj);
      this.form.filiais.dadosSelect = [];
      this.form.filiais.dadosSelect.push(matriz);
      filiais.forEach((filial) => {
        filial.nomeDescricao =
          filial.nome_fantasia + " - " + this.$helpers.formataCnpj(filial.cnpj);
        this.form.filiais.dadosSelect.push(filial);
      });
      if (this.form.filiais.dadosSelect.length > 0) {
        if (this.form.filiais.dadosSelect.length == 1) {
          this.form.filiais.selecionado = this.form.filiais.dadosSelect[0];
          this.setFiliais(this.form.filiais.selecionado);
        }
        this.form.filiais.disabled = false;
      }
    },
    setFiliais(event){
      if (!event) {
        this.limpaForm();
        return;
      }
      this.disabledFormCriacao(false);
      this.buscaFasesDisponiveis(
        this.form.filiais.selecionado.id_empresa
      );
    },
    buscaFasesDisponiveis($empresa) {
      this.loading = true;
      let rota = this.$helpers.preparaRotaComMultiplosParametros(
        this.$api.DashboardPlanejaCiclos,
        {
          "{idEmpresa}": $empresa,
        }
      );
      this.$http
          .get(rota)
          .then((res) => {
            if (res.status == 200) {
              let dados = res.data;
              dados.forEach((dado) => {
                let ciclo = dado.descricao;
                ciclo = ciclo.replace('Ciclo', 'Fase');
                dado.descricao = ciclo;
              });

              
              this.form.fases.dadosSelect = dados.sort((a, b) => {
                return a.descricao.localeCompare(b.descricao);
              });
              this.loading = false;
            }
            if (res.status == 204) {
              this.form.fases.dadosSelect = [];
              this.loading = false;
            }
          })
          .catch((error) => {
            this.ErroAPI(error);
          });
    },
    limpaForm() {
      this.$refs.simpleRules.reset();
      this.form.filiais.selecionado = "";
      this.form.grupo.selecionado = "";
      this.form.fases.selecionado ="";
      this.disabledFormCriacao(true);
    },
    FormataDadosFormulario() {
      return {
        id_empresa:this.form.filiais.selecionado.id_empresa,
        id_fase: this.form.fases.selecionado,
      };
    },
    ordenarTabela(contextoTabela) {
      this.tabela.campoOrdenado = contextoTabela.sortBy;
      this.tabela.ordem = contextoTabela.sortDesc ? "desc" : "asc";
      this.listaRelatorios();
    },
    geraRelatorio() {
      this.loading = true;
      let rota = this.$api.PlanejaSaude.gerarRelatorioAcompanhamento(
        this.form.filiais.selecionado.id_empresa,
        this.form.fases.selecionado.id_fase
      );
      (async () => {
        try {
          await
            this.$http
              .get(rota)
              .then(response => {
                if(response.status == 200){
                  modalGenericModel(
                    'Em processamento',
                    'O arquivo está sendo processado!',
                    require('@/assets/custom-icons/cora-icons/success.png'),
                    'Ok'
                  )
                  this.listaRelatorios();
                }
              })
              .catch((error) => {
                modalGenericModel(
                  'Erro no processamento',
                  error.response.data.error,
                  require('@/assets/custom-icons/cora-icons/error.png'),
                  'Ok'
                )
              });
        } catch (err) {
          modalGenericModel(
            'Erro inesperado',
            'Aconteceu um erro inesperado',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
        }
        this.limpaForm();
        this.loading = false;
      })();
    },
    submitForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          modalGenericModel(
            'Gerar Relatório',
            'Ao clicar em "Confirmar", sua solicitação será enviada para processamento. ' +
            'Você será notificado quando a exportação do relatório for concluída.',
            require('@/assets/custom-icons/cora-icons/help.png'),
            'Confirmar',
            'Cancelar'
          ).then((result) => {
            if (result.isConfirmed) {
              this.geraRelatorio();
            }
          });
        }
      });
    },
    moment(date, format) {
      return moment(date, format);
    },
    baixarZip(response){
      const link = document.createElement('a');
      link.href = URL.createObjectURL(response.data);
      link.download = response.headers["content-name-file"];
      link.click();
      URL.revokeObjectURL(link.href);
    },
    downloadRelatorio(item){
      this.loading = true;
      let rota = this.$api.PlanejaSaude.baixarRelatorioAcompanhamento(
        item.id_empresa,
        item.id_fase
      );
      (async () => {
        try {
          await
            this.$http
              .get(rota, {responseType: "blob"})
              .then(response => {
                this.baixarZip(response);
              })
              .catch((error) => {
                modalGenericModel(
                  'Erro no processamento',
                  error.response.data.error,
                  require('@/assets/custom-icons/cora-icons/error.png'),
                  'Ok'
                )
              });
        } catch (err) {
          modalGenericModel(
            'Erro inesperado',
            'Aconteceu um erro inesperado',
            require('@/assets/custom-icons/cora-icons/error.png'),
            'Ok'
          )
        }
        this.limpaForm();
        this.loading = false;
      })();
    },
  },
};
</script>
